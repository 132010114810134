import React, { useState } from "react";

const store = {};
const Context = React.createContext(null);

const ContextProvider = (props) => {
  const [state, setState] = useState(store);

  return (
    <Context.Provider value={[state, setState]}>
      {props.children}
    </Context.Provider>
  );
};

export { Context, ContextProvider };
