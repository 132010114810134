import React, { useContext, useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { getCookie } from "../../utils/cookie";
import req from "../../utils/req";

const PrivatRoute = ({ children, ...props }) => {
  const [isSatisfied, setSatisfied] = useState(0);

  useEffect(() => {
    let token = getCookie("ad");
    // console.log(token);

    const checkToken = async (token) => {
      try {
        let { data } = await req.post("auth/checktoken", { token: token });
        // console.log("resp", data.resp);
        setSatisfied(data.resp);
      } catch (error) {
        setSatisfied(2);
      }
    };

    // if (token) {
      checkToken(token);
    // }
  }, []);

  return (
    <>
      {isSatisfied === 1 ? <Route {...props}>{children}</Route> : null}
      {isSatisfied === 2 ? <Redirect to={`/signin`} /> : null}
    </>
  );
};

export default PrivatRoute;
