import { Button } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";

import { Context } from "../../context/context";
import styles from "./styles.module.scss";
// import clients from "../../clients/clients.json";
import logo from "../../assets/imgs/logo.png";
import req from "../../utils/req";
import { deleteCookie, getCookie } from "../../utils/cookie";

const Admin = () => {
  const [state, setState] = useContext(Context);

  const history = useHistory();
  
  useEffect(() => {
    const _element = document.querySelector("body");
    if (_element) {
      _element.style.backgroundColor = "#f2f2f2";
    }

    let token = getCookie("ad");

    if (!state.clients && token) {
      const getClients = async () => {
        try {
          const { data } = await req.get("clients/getclients", {headers: {'Authorization': token}});
          setState((prev) => ({ ...prev, clients: [...data] }));
        } catch (error) {
          console.log(error);
        }
      };
      getClients();
    }

    return () => {
      if (_element) {
        _element.style.backgroundColor = "#000";
      }
    };
  }, []);

  const handleDownload = () => {
    if (state && state.clients && state.clients.length) {
      const csvContent =
        "data:text/csv;charset=utf-8," +
        state.clients
          .map((item) => {
            const itemStr = `${item.name}, ${item.email}, ${item.time}`;
            return itemStr;
          })
          .join("\n");

      console.log(csvContent);

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `data_${moment().format("L")}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const onLogOut = () => {
    deleteCookie("ad");
    history.push("/signin");
  };

  return (
    <div className={styles.container}>
      <header>
        <div className={styles.logo}>
          <img src={logo}></img>
        </div>
        {/* <h4>Text</h4> */}
        <div className={styles.divCont}>
          <div className={styles.divCont_div}>
            <a className={styles.link} onClick={handleDownload}>
              Download
            </a>
          </div>
          <div className={styles.divCont_div}>
            <button className={styles.logout} onClick={onLogOut}>
              Log out
            </button>
          </div>
        </div>
      </header>
      {state.clients && state.clients.length && (
        <table className={styles.table} border={1}>
          <thead className={styles.head}>
            <tr className={styles.row}>
              <th style={{ width: "30px" }}>№</th>
              <th>Name</th>
              <th>Email</th>
              <th>Time</th>
              <th width="50">Contact Sales</th>
              <th width="300">Liked Devices</th>
            </tr>
          </thead>
          <tbody className={styles.body}>
            {state.clients.map((item, index) => (
              <tr className={styles.row} key={item._id}>
                <td style={{ width: "30px" }}>{index + 1}</td>
                <td>{item.name}</td>
                <td>{item.email}</td>
                <td>{item.time}</td>
                <td width="50">{item.sales ? "yes" : "no"}</td>
                <td width="300">{item.likedDev.join(", ")}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Admin;
