import React from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import WebXRPolyfill from "webxr-polyfill";
import { Async, lazyImport } from "./Async";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import PrivatRoute from './components/PrivatRoute/PrivatRoute'
import Admin from "./pages/admin/admin";
import Signin from "./pages/signin/signin";
import { ContextProvider } from "./context/context";
// import NotFound from "./pages/NotFound/NotFound";
// const polyfill =

new WebXRPolyfill({
  webvr: false,
});

const Tour = Async(lazyImport("./pages/netflixTour/Tour360"));
// const NotFound = Async(lazyImport("./pages/NotFound/NotFound"));

const App = () => (
  <>
    <ContextProvider>
      <Router>
        <Switch>
          <Route exact path={"/"} component={Tour} />
          <PrivatRoute path={"/admin"}><Admin/></PrivatRoute>
          <Route  path={"/signin"} component={Signin} />
          {/* <Route component={NotFound} /> */}
        </Switch>
      </Router>
      <ToastContainer />
    </ContextProvider>
   
  </>
);

export default App;
